import React from 'react';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import BrandBanner from '../components/BrandBanner';
import BrandVideo from '../components/BrandVideo';

const brandTemplate = props => {
  const { pageContext } = props;
  const { pageContent } = pageContext;
  const {
    heroSection,
    detailSection,
    videoSection,
    visitSection,
  } = pageContent;

  return (
    <Layout>
      <Header />
      <BrandBanner extraClasses={heroSection.class} videoId={heroSection.videoId}>
        {heroSection.img && heroSection.img.src && (
          <img
            src={`${heroSection.img.src}`}
            srcSet={`${heroSection.img.srcSet}`}
            alt={`${heroSection.img.alt}`}
            className={`${heroSection.img.class}`}
          ></img>
        )}
        <p>{`${heroSection.caption}`}</p>
      </BrandBanner>

      <section className={`brand-details ${detailSection.class}`}>
        <div className="container">
          <h2 className="mx-auto">{`${detailSection.title}`}</h2>
          <p className="mx-auto">{`${detailSection.subTitle}`}</p>
          <div className="row fan-view">
            <div className="col-lg-5 col-sm-5">
              <h3 className="">
                <span>{`${detailSection.socialFans}`}</span>
                <br className="break-hide-for-mobile"></br>
                {`${detailSection.socialFansText}`}
              </h3>
            </div>
            <div className="col-lg-7 col-sm-7">
              <div className="audiance">
                <img
                  src={`${detailSection.description1.img.src}`}
                  srcSet={`${detailSection.description1.img.srcSet}`}
                  alt={`${detailSection.description1.img.alt}`}
                  className={`${detailSection.description1.img.class}`}
                ></img>
                <h6>{`${detailSection.description1.title}`}</h6>
                <p>{`${detailSection.description1.content}`}</p>
                <hr className="hr-border"></hr>
              </div>
            </div>
          </div>
          <div className="row fan-view">
            <div className="col-lg-5 col-sm-5">
              <h3 className="video-view-month ">
                <span>{`${detailSection.videoViews}`}</span>
                <br className="break-hide-for-mobile"></br>{' '}
                {`${detailSection.videoViewsText}`}
              </h3>
            </div>
            <div className="col-lg-7 col-sm-7">
              <div className="audiance">
                <img
                  src={`${detailSection.description2.img.src}`}
                  srcSet={`${detailSection.description2.img.srcSet}`}
                  alt={`${detailSection.description2.img.alt}`}
                  className={`${detailSection.description2.img.class}`}
                ></img>
                <h6>{`${detailSection.description2.title}`}</h6>
                <p>{`${detailSection.description2.content}`}</p>
                <hr className="hr-border"></hr>
              </div>
            </div>
          </div>
          <div className="row fan-view">
            <div className="col-lg-5 col-sm-5">
              <h3 className="avg-ctv-month ">
                <span>{`${detailSection.likes}`}</span>
                <br className="break-hide-for-mobile"></br>
                {`${detailSection.likesText}`}
              </h3>
            </div>
          </div>
        </div>
      </section>

      <BrandVideo heading={videoSection.title} videoId={videoSection.videoId} />

      <section className="visit-all">
        <div className="social-share">
          <a
            href={` ${visitSection.visitLink.href} `}
            className={`visit-fall-army flex-align ${visitSection.visitLink.class}`}
          >
            {` ${visitSection.visitLink.title} `}
            <i className="fa fa-chevron-right pl-2"></i>
          </a>
        </div>
        <div className="social-media-logo flex-align">
          <a href={` ${visitSection.facebookLink} `}>
            <img
              src="/all/facebook.png"
              srcSet="/all/facebook@2x.png, /all/facebook@3x.png"
              alt="facebook"
              className="social-image"
            ></img>
          </a>
          <a href={` ${visitSection.instagramLink} `}>
            <img
              src="/all/instagram.png"
              srcSet="/all/instagram@2x.png, /all/instagram@3x.png"
              alt="instagram"
              className="social-image"
            ></img>
          </a>
          <a href={` ${visitSection.youtubeLink} `}>
            <img
              src="/all/you-tube.png"
              srcSet="/all/you-tube@2x.png, /all/you-tube@3x.png"
              alt="you-tube"
              className="social-image"
            ></img>
          </a>
          <img
            src="/all/snap.png"
            srcSet="/all/snap@2x.png, /all/snap@3x.png"
            alt="snapchat"
            className="social-image"
          ></img>
        </div>
      </section>
      <SocialLinks />
      <Footer />
    </Layout>
  );
};
export default brandTemplate;
